import React, { useEffect, useState } from 'react'
import api from '../js/api'
import { isMobile, isDesktop } from 'react-device-detect'
import { Link, useParams } from 'react-router-dom'
import exampleimage from '../img/projects/example.png'
import examplelogo from '../img/projects/milwaukee.png'
import examplebanner from '../img/projects/milwaukee/milwaukee3.webp'
import { CaseStudyBack } from '../Components/CaseStudyBack'
import { CaseStudyTitle } from '../Components/CaseStudyTitle'
import { CaseStudyBanner } from '../Components/CaseStudyBanner'
import { CaseStudyHybridSection } from '../Components/CaseStudyHybridSection'
import { CaseStudyTextSection } from '../Components/CaseStudyTextSection'
import { CaseStudyImageSection } from '../Components/CaseStudyImageSection'

export const CaseStudy = () => {
  const { caseStudy } = useParams()

  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      console.log(caseStudy)
      const result = await api.Pages.caseStudy(caseStudy)
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])

  let pageData
  if (data) {
    pageData = data.data[0].attributes
    console.log(pageData)
  }

  const caseStudyTitle = 'Milwaukee HUB'
  const caseStudyCompany = 'Milwaukee'
  const caseStudyTags = ['realtime', 'metaverse', 'pixelstreaming']
  const caseStudyLogo = examplelogo
  const caseStudyBlurb = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pharetra leo a velit luctus, non ornare ligula congue. In pretium gravida imperdiet. Vestibulum non elit sit amet nisi volutpat porttitor vitae non eros. Nam mollis eleifend ex, nec commodo leo faucibus vitae. Suspendisse interdum lobortis mi ut fringilla. Phasellus et erat eu tellus consequat molestie. Donec consequat est non pulvinar tristique.'
  const caseStudyParagraph = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pharetra leo a velit luctus, non ornare ligula congue. In pretium gravida imperdiet. Vestibulum non elit sit amet nisi volutpat porttitor vitae non eros. Nam mollis eleifend ex, nec commodo leo faucibus vitae. Suspendisse interdum lobortis mi ut fringilla. Phasellus et erat eu tellus consequat molestie. Donec consequat est non pulvinar tristique. \n \n Fusce ut dui sodales lorem malesuada volutpat. Vivamus vehicula pretium tortor, pulvinar venenatis massa suscipit nec. Duis sit amet convallis dui. Integer consequat ex in varius scelerisque. Morbi a porta lectus, et rhoncus mi. Sed pulvinar urna metus, quis lobortis turpis elementum non. Suspendisse potenti. Proin sed interdum sapien. Vestibulum ullamcorper quis neque non pretium.'
  const caseStudyGallery = [examplebanner, examplebanner]
  const caseStudyTestemonials = [examplelogo, examplelogo, examplelogo, examplelogo, examplelogo, examplelogo]

  return (
    <>
      <div className='content min-h-screen bg-invidarMedium'>
        <div className='flex flex-wrap flex-col md:flex-row w-full h-full pt-28 pb-12 justify-center'>
          <CaseStudyBack to='/casestudies' title='Case Studies' />
          <CaseStudyTitle title={caseStudyTitle} company={caseStudyCompany} tags={caseStudyTags} logo={caseStudyLogo} blurb={caseStudyBlurb} />
          <CaseStudyBanner img={examplebanner} />
          <CaseStudyHybridSection title='The problem' img={caseStudyLogo} text={caseStudyParagraph} direction='right' />
          <CaseStudyHybridSection title='The solution' img={caseStudyLogo} text={caseStudyParagraph} direction='left' />
          <CaseStudyTextSection title='The result' text={caseStudyParagraph} />
          <CaseStudyImageSection imgs={caseStudyGallery} cols='2' />
          <CaseStudyImageSection title='Metrics/Testimonials' imgs={caseStudyTestemonials} cols='3' />
        </div>
      </div>
    </>
  )
}

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import Typed from 'typed.js'

// import Player from '@vimeo/player'

// import modelling from '../img/Landing/modelling.png'
// import development from '../img/Landing/development.png'
// import realtime from '../img/Landing/realtime.png'
import background from '../img/Landing/background.webp'

export const InvidarLandingVideoNew = (data) => {
  $(function () {
    // const videoBackground = $('#videoBackground')
    // const videoBackgroundPlayer = new Player(videoBackground)
  })

  const typeel = React.useRef(null)
  const strings = [
    // 'Inventing <span>realities</span> for business benefit.',
    'Unlocking the potential of <span>real-time</span> 3D technology.',
    'Merging bespoke <span>full-stack</span> solutions with 3D innovations.',
    'Driving Innovation with <span>Immersive</span> 3D Technologies.',
    'Delivering global <span>solutions</span> for agencies and brands.']
  // const strings = pageData?.homepageTitles?.data.map((title) => title.attributes.Title)
  useEffect(() => {
    const typed = new Typed(typeel.current, {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 25,
      startDelay: 50,
      backDelay: 2000,
      loop: true
    })

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy()
    }
  }, [])

  return (
    <div id='landing' className='relative bg-invidarDark flex justify-center lg:overflow-hidden min-w-screen min-h-screen'>
      <div id='videoBackgroundWrapper' className='hidden lg:block absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden z-0' allow='autoplay; fullscreen;'>
        <iframe id='videoBackground' title='Homepage Video Background' className='w-full center-fully video-background-iframe' src='https://player.vimeo.com/video/1036326141?h=9dd13ffce4?h=2e5b4f11e8&background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1' allow='autoplay; fullscreen; picture-in-picture' />
      </div>
      <div className='w-screen h-screen lg:hidden bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${background})` }}>
        <div className='w-screen h-screen bg-black opacity-40' />
      </div>
      {/* <div className='absolute w-1/2 h-full right-0' style={{ background: 'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.15) 20%, rgba(0,0,0,0.05) 30%, rgba(0,0,0,0.05) 80%, rgba(0,0,0,0.15) 85%, rgba(0,0,0,0.8) 100%)' }} /> */}
      {/* <div className='absolute w-1/2 h-full left-0' style={{ background: 'rgba(0,0,0,0.3)' }} /> */}
      <div className='absolute w-full h-full right-0' style={{ background: 'linear-gradient(0deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.75) 20%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.75) 80%, rgba(0,0,0,0.95) 100%)' }} />
      {/* <div className='absolute z-0 bg-white bottom-0 w-full h-64'></div> */}
      <div className='flex flex-row flex-wrap gap-6 absolute left-4 md:left-1/8 bottom-[30%] sm:bottom-[40%] md:bottom-[35%] w-[92vw] md:w-[60vw] lg:w-[45vw]'>
        <h1 className='font-menlo text-[1.75rem] sm:text-[2rem] md:text-[2.5rem] font-black text-white'><span id='typed' ref={typeel} /></h1>
        <p className='font-avenir text-[0.8rem] md:text-[1rem] mb-4 text-white font-light'>We harness real-time 3D technology and merge bespoke software solutions with the latest immersive innovations to deliver global, award-winning solutions tailored to your industry—including healthcare, corporate training, smart cities, defence, and entertainment. By driving innovation with immersive 3D technologies, we create engaging experiences that set you apart from the competition.</p>
        <Link to='/about'>
          <button className='font-avenir text-[1.2rem] w-52 h-12 font-normal uppercase bg-black text-white shadow-invidarPrimary shadow-[4px_4px] tracking-wider
          hover:shadow-[6px_6px] hover:shadow-invidarPrimary hover:tracking-widest transition-all'
          >
            About
          </button>
        </Link>
        <Link to='/contact'>
          <button className='font-avenir text-[1.2rem] w-52 h-12 font-normal uppercase bg-white text-black shadow-invidarPrimary shadow-[4px_4px] tracking-wider
          hover:shadow-[6px_6px] hover:shadow-invidarPrimary hover:tracking-widest transition-all'
          >
            Contact
          </button>
        </Link>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import api from '../js/api'
import { useLocation } from 'react-router-dom'
import { InvidarLandingVideoNew } from './InvidarLandingNew'
export const LandingFront = (props) => {
  const location = useLocation()

  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.homepage()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {(location.pathname === '/' || props.showLandingAnimation) &&
        <div id='landingFront' className='h-full w-full top-0 relative'>
          <InvidarLandingVideoNew data={data} />
        </div>}
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const CaseStudyTitle = ({ title, company, tags, logo, blurb }) => {
  return (
    <>
      <div className='w-full xl:w-4/5 2xl:w-3/4 my-6 px-8'>
        <div className='flex flex-row flex-wrap md:flex-nowrap'>
          <div className='flex flex-col flex-grow w-full md:w-auto'>
            <h1 className='text-white font-menlo font-bold text-[2rem] md:text-[3rem] lg:text-[4rem]'> {title} </h1>
            <p className='text-white font-menlo font-bold text-[2rem] md:text-[3rem] lg:text-[4rem]'> {company} </p>
          </div>
          <div className='flex w-full md:w-auto justify-center'>
            <img className='h-full w-auto self-center object-contain' src={logo} />
          </div>
        </div>
        <div className='flex flex-row flex-wrap gap-2 mt-6'>
          {tags.map((tag, index) => {
            return (
              <>
                {index >= 1 && <div className=' w-1 h-5 bg-invidarPrimary -skew-x-12'></div>}
                <p key={index} className='text-white text-sm font-bold uppercase tracking-wider whitespace-pre-line'>{tag}</p>
              </>
            )
          })}
        </div>
        <div className='text-white mt-12'>
            {blurb}
        </div>
      </div>
    </>
  )
}

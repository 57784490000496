import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

export const CaseStudyBack = ({ to, title }) => {
  return (
    <>
        <div className='w-full xl:w-4/5 2xl:w-3/4 mt-12 px-8'>
            <Link to={to} className='text-white hover:-ml-2 transition-all tracking-wide'>
                <i class="inline-block p-[0.35rem] -mb-[0.1rem] mr-2 border-solid border-invidarPrimary border-b-2 border-l-2 rotate-45"></i>
                Back to {title}
            </Link> 
        </div>
    </>
  )
}

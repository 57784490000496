import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'

export const DemoForm = ({ title, name, email, message, buttonSendText }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: 'Demo Request'
  })
  const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ ]+$/
  const [errName, setErrName] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [t] = useTranslation('global')

  function handleOnChange (e) {
    if (e.target.name === 'name') {
      setErrName('')
    } else if (e.target.name === 'email') {
      setErrEmail('')
    } else if (e.target.name === 'message') {
      setErrMessage('')
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  $(function () {
    const message = $('#contactmessage')
    const hiddenMessage = $('#hiddenMessage')
    message.on('input', function () {
      message.attr('value', message.html())
      hiddenMessage.attr('value', message.html())
      setErrMessage('')
      form.message = message.html()
    })
  })

  function verifyField () {
    const testEmail = emailRegex.test(form.email)
    console.log(Object.entries(form))
    Object.entries(form).forEach(value => {
      if (value[0] === 'name' && value[1].length <= 2) {
        setErrName(t('app.errname'))
      } else if (value[0] === 'email') {
        if (value[1].length <= 6) {
          setErrEmail(t('app.erremail'))
        } else if (!testEmail) {
          setErrEmail(t('app.erremailformat'))
        }
      // } else if (value[0] === 'message' && value[1].length < 20) {
      //   setErrMessage(t('app.errmessage'))
      }
    })
  }

  function handleOnSubmit (e) {
    e.preventDefault()
    const formDiv = document.getElementById('contactform')
    verifyField()
    if ((errName === '' && form.name.length > 2) && (errEmail === '' && emailRegex.test(form.email))) {
      formDiv.action = 'https://formspree.io/f/mgedpzqg'
      formDiv.submit()
    } else {
      console.warn('Message not sent')
    }
  }

  return (
    // <div className='relative'>
    //   <div className='contactform-wrapper bg-invidarDark w-full h-full px-4 py-8 sm:p-8 lg:absolute'>
    //     <form id='contactform' method='POST' onSubmit={handleOnSubmit} className=''>
    //       <h2 className='text-invidarPrimary text-2xl pb-6'>{title}</h2>
    //       <div className='flex flex-col relative mt-12'>
    //         <input className='contactinput order-2 mt-1 py-1 bg-transparent border-invidarDark border-b-2' required onChange={handleOnChange} placeholder=' ' value={form.name} type='text' name='name' id='contactname' />
    //         <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactname'>{name}</label>
    //         {(errName !== '') && <label className='text-invidarPrimary mt-2'>{errName}</label>}
    //       </div>
    //       <div className='flex flex-col relative mt-16'>
    //         <input className='contactinput order-2 mt-1 py-1 bg-transparent border-invidarDark border-b-2' required onChange={handleOnChange} placeholder=' ' value={form.email} type='text' name='email' id='contactemail' />
    //         <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactemail'>{email}</label>
    //         {(errEmail !== '') && <label className='text-invidarPrimary mt-2'>{errEmail}</label>}
    //       </div>
    //       <div className='flex flex-col relative mt-16'>
    //         <span className='contactspaninput order-2 relative w-full input block min-h-12 mt-1 py-1 bg-transparent border-invidarDark border-b-2 pt-2 resize-y outline-0' role='textbox' contentEditable='true' required onChange={handleOnChange} placeholder=' ' value={form.message} type='text' id='contactmessage' />
    //         <input className='contacthiddeninput hidden' id='hiddenMessage' type='text' name='message' placeholder=' ' />
    //         <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactmessage'>{message}</label>
    //         {(errMessage !== '') && <label className='text-invidarPrimary mt-2'>{errMessage}</label>}
    //       </div>
    //       <div className='flex mt-10 justify-start text-lg'>
    //         <button className='invidar-about-button relative text-md font-thin border-2 border-invidarPrimary w-fit'>
    //           <div className='invidar-about-button-bar absolute top-0 bottom-0 h-full bg-invidarLight' />
    //           <p className='mix-blend-difference text-invidarLight text-xl'>{buttonSendText}</p>
    //         </button>
    //       </div>
    //     </form>
    //   </div>
    // </div>
    <div className='flex flex-row justify-center'>
      <div className='col-span-1 md:col-span-2 lg:pr-4'>
        <p className='font-semibold text-lg'>Fill out the contact form and we will be in touch soon</p>
        <form id='contactform' method='POST' onSubmit={handleOnSubmit} className='grid grid-cols-1 md:grid-cols-2 mt-8 gap-8'>
          <div className='flex relative md:col-span-2 lg:col-span-2'>
            <input className='w-full order-2 contactinput mt-1 p-2 bg-transparent text-lg font-light border-white text-white placeholder:text-white border-[1px]' required onChange={handleOnChange} placeholder=' ' value={form.name} type='text' name='name' id='contactname' />
            <label className='contactlabel order-1 pointer-events-none text-white font-light' htmlFor='#contactname'>Name</label>
          </div>
          <div className='flex relative md:col-span-2 lg:col-span-2'>
            <input className='w-full order-2 contactinput mt-1 p-2 bg-transparent text-lg font-light border-white text-white placeholder:text-white border-[1px]' required onChange={handleOnChange} placeholder=' ' value={form.email} type='email' name='email' id='contactemail' />
            <label className='contactlabel order-1 pointer-events-none text-white font-light' htmlFor='#contactemail'>Email</label>
          </div>
          <button className='md:col-span-2 text-md font-medium border-2 border-white bg-white w-full py-2 shadow-invidarPrimary
          shadow-[4px_4px]  tracking-wider hover:shadow-[6px_6px] hover:shadow-invidarPrimary hover:tracking-widest transition-all'>
            <p className='text-black text-xl uppercase'>Request Demo</p>
          </button>
        </form>
      </div>
    </div>
  )
}

import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import logo from '../img/logos/logo.svg'

export default function Footer () {
  const location = useLocation()
  const [t] = useTranslation('global')
  return (
    <footer id='footer' className={(location.pathname.includes('projects') ? 'bg-black' : 'bg-invidarDark') + ' mt-8 text-black bottom-0 relative h-full w-full flex flex-col flex-wrap lg:flex-row lg:justify-between px-6 lg:px-12 pb-4 lg:pb-10 '} aria-labelledby='footer-heading'>
      <div className='hidden lg:flex font-bold w-4/12 text-white text-xl pb-6 md:pl-12 relative min-w-max'>
        <div className='relative w-full'>
          <span className='border-invidarLight absolute -bottom-5 -ml-6 h-28 w-28 border-b-2 border-l-2' />
          <p className='w-52 font-extralight strapline-mini pl-3 pb-6'>{t('app.inventingrealities')}</p>
        </div>
      </div>
      <div className='flex flex-col gap-8 w-full lg:w-2/12 font-avenir text-md font-light tracking-wider'>
        <Link to='/terms' className='hover:text-invidarPrimary text-white w-full text-left'>Terms and Conditions</Link>
        <Link to='/privacy' className='hover:text-invidarPrimary text-white w-full text-left'>Privacy Policy</Link>
        <Link to='/antislavery' className='hover:text-invidarPrimary text-white w-full text-left'>Anti-Slavery Policy</Link>
      </div>
      <div className='hidden lg:flex w-6/12 text-white text-lg font-light justify-end font-avenir tracking-wider'>
        <button className='h-fit'>
          CONTACT
        </button>
      </div>
      <div className='w-full mt-6 lg:ml-6'>
        <p className='text-white text-xs text-left font-avenir w-auto'>{t('app.footer')}</p>
      </div>
    </footer>
  )
}

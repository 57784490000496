import React, { useEffect, useState } from 'react'
import { AboutClients } from '../Components/AboutClients'
import api from '../js/api'
import { EmployeeCards } from '../Components/EmployeeCards'
import exampleimage from '../img/projects/example.png'
import { getImgFullURL } from '../js/helpers/imgHelper'

export const About = ({ setShowContact }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.aboutUs()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])

  let clients, team, pageData
  if (data) {
    pageData = data.data.attributes
    clients = pageData.companies_work_withs
    team = pageData.employees.data
    console.log(pageData)
  }

  return (
    <div className='content min-h-screen bg-invidarMedium'>
      <div className='flex flex-wrap flex-col md:flex-row w-full h-full pt-28 pb-6 justify-center'>
        <div className='flex flex-row flex-wrap w-full mb-12 bg-invidarDark justify-center'>
          <div className='w-full xl:w-4/5 2xl:w-3/4 py-8 px-8'>
            <iframe id='videoBackground' title='Homepage Video Background' className='w-full h-[10rem] sm:h-[20rem] lg:h-[40rem]' src={pageData?.VideoUrl} allow='autoplay; fullscreen; picture-in-picture'/>
          </div>
        </div>
        <div className='w-full xl:w-4/5 2xl:w-3/4 my-12 px-8'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            <div className='flex flex-col lg:md:col-span-2  order-1'>
              <h1 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'>{pageData?.Section1Title}</h1>
              <p className='text-white whitespace-pre-line'>{pageData?.Section1Content}</p>
            </div>
            <div className='relative flex flex-row flex-wrap justify-center order-2 place-self-center'>
              <div className='absolute top-1/2 translate-y-[-50%] bg-gradient-to-tr from-invidarMedium to-invidarDark shadow-invidarDark shadow-[0_0_12px] w-full h-[75%]' />
              <img className='place-self-center w-[90%] z-10' src={getImgFullURL(pageData?.Section1Image?.data.attributes.url)} />
            </div>
            <div className='flex flex-col lg:col-span-2 order-3 md:order-4'>
              <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'>{pageData?.Section2Title}</h2>
              <p className='text-white whitespace-pre-line'>{pageData?.Section2Content}</p>
            </div>
            <div className='relative flex flex-row flex-wrap justify-center order-4 md:order-3 place-self-center'>
              <div className='absolute top-1/2 translate-y-[-50%] bg-gradient-to-tr from-invidarMedium to-invidarDark shadow-invidarDark shadow-[0_0_12px] w-full h-[75%]' />
              <img className='place-self-center w-[90%] z-10' src={getImgFullURL(pageData?.Section2Image?.data.attributes.url)} />
            </div>
            <div className='flex flex-col lg:col-span-2  order-5'>
              <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'>{pageData?.Section3Title}</h2>
              <p className='text-white whitespace-pre-line'>{pageData?.Section3Content}</p>
            </div>
            <div className='relative flex flex-row flex-wrap justify-center order-6 place-self-center'>
              <div className='absolute top-1/2 translate-y-[-50%] bg-gradient-to-tr from-invidarMedium to-invidarDark shadow-invidarDark shadow-[0_0_12px] w-full h-[75%]' />
              <img className='place-self-center w-[90%] z-10' src={getImgFullURL(pageData?.Section3Image?.data.attributes.url)} />
            </div>
          </div>
        </div>
        <AboutClients workWithTitle={pageData?.WhoWorkWithTitle} clients={clients} />
        <div className='w-full xl:w-4/5 2xl:w-3/4 my-12 px-8'>
          <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4'>{pageData?.MeetTheTeamTitle}</h2>
          <EmployeeCards meetTheTeamTitle={pageData?.meetTheTeamTitle} employees={team} />
        </div>
      </div>
    </div>
  )
}

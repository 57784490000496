import React, { useEffect, useState } from 'react'
import api from '../js/api'
import { isMobile, isDesktop } from 'react-device-detect'
import { Link } from 'react-router-dom'
import exampleimage from '../img/projects/example.png'
import examplelogo from '../img/projects/milwaukee.png'
import examplebanner from '../img/projects/milwaukee/milwaukee3.webp'
import { getImgFullURL } from '../js/helpers/imgHelper'

export const CaseStudies = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.caseStudies()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])

  let pageData, featuredCaseStudy, caseStudies
  if (data) {
    pageData = data.data.attributes
    featuredCaseStudy = pageData.featured_case_study.data.attributes
    caseStudies = pageData.case_studies
    console.log(pageData)
    console.log(featuredCaseStudy)
    console.log(caseStudies)
  }

  return (
    <>
      <div className='content min-h-screen bg-invidarMedium'>
        <div className='flex flex-wrap flex-col md:flex-row w-full h-full pt-28 justify-center'>
          <div className='w-full xl:w-4/5 2xl:w-3/4 mt-12 px-8'>
            <h1 className='text-white font-menlo font-bold text-[2.5rem]'>{pageData?.Title}</h1>
            <p className='text-white font-light text-[1.125rem] mt-6'>
              {pageData?.Description}
            </p>
          </div>
          <div className='w-full mt-12'>
            <img src={getImgFullURL(featuredCaseStudy?.banner)} className='w-full h-120 object-cover' />
          </div>
          <div className='w-full xl:w-4/5 2xl:w-3/4 px-8'>
            <div className='xl:w-4/5 2xl:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-4 bg-invidarDark p-8 -mt-32 w-full lg:w-3/4'>
              <div className='text-white'>
                <h2 className='mb-4 text-[2rem] font-menlo font-bold'>{featuredCaseStudy?.ProjectName}</h2>
                <p className='mb-4'>{featuredCaseStudy?.Description}
                </p>
                <Link to={featuredCaseStudy?.slug}>
                  <button className='font-avenir text-[1.2rem] w-52 h-12 font-normal uppercase bg-white text-black shadow-invidarPrimary shadow-[4px_4px] tracking-wider
                    hover:shadow-[6px_6px] hover:shadow-invidarPrimary hover:tracking-widest transition-all'>
                    Learn More
                  </button>
                </Link>
              </div>
              <div className='order-first md:order-last'>
                <img src={getImgFullURL(featuredCaseStudy?.logo)} />
              </div>
            </div>
          </div>
          <div className='w-full xl:w-4/5 2xl:w-3/4 my-12 px-8'>
            <h1 className='text-white font-menlo font-bold text-[2rem] mb-4'>More case studies</h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
              {caseStudies?.data.map((project, index) => {
                return (
                  <Link key={index} to={project.attributes.slug} className='group'>
                    <div className='flex flex-col'>
                      <div className='w-11/12 self-start group-even:self-end md:group-even:self-start shadow-invidarDark shadow-[6px_6px] group-hover:shadow-[10px_10px] group-hover:shadow-invidarDark transition-all overflow-hidden'>
                        <img className='w-full h-full object-cover group-hover:scale-105 transition-all' src={project.attributes.IconImage} />
                      </div>
                      <div className='w-11/12 z-10 -mt-5 self-end group-even:self-start md:group-even:self-end bg-invidarDark text-white text-lg font-semibold font-menlo py-3 px-4 transition-all group-hover:tracking-wider'> {project.attributes.ProjectName} </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { EmployeeCard } from './EmployeeCard'
import { getImgFullURL } from '../js/helpers/imgHelper'

export const EmployeeCards = ({ employees }) => {
  const workers = employees?.map((worker) => {
    return { name: worker.attributes.Name, role: worker.attributes.PositionTitle, img: getImgFullURL(worker.attributes.Picture?.data.attributes.url), description: worker.attributes.Description }
  })
  console.log(workers)
  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
        {workers?.map((employee, index) => {
          return (
            <EmployeeCard key={index} name={employee.name} role={employee.role} text={employee.description} img={employee.img} />
          )
        })}
      </div>
    </>
  )
}

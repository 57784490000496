import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const CaseStudyImageSection = ({ title, imgs, cols }) => {
  return (
    <>
        <div className='w-full xl:w-4/5 2xl:w-3/4 my-6 px-8'>
            <div className='flex flex-col'>
                <div className='w-full'>
                    {title &&
                        <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'> {title} </h2>
                    }
                </div>
                <div className={'grid gap-4 w-full ' + (cols && cols > 1 ? 'dynamic-cols-'+cols : '')}>
                    {imgs.map((img, index) => {
                        return (
                            <>
                                <img key={index} className='h-full w-auto object-contain' src={img} />
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    </>
  )
}

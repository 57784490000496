import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const EmployeeCard = ({ name, role, img, text }) => {
  return (
    <>
      <div>
        <div className='mt-8 mb-6'>
          <div className='relative flex flex-row flex-wrap justify-center group'>
            <div className='absolute bg-invidarDark border-b-[1px] border-invidarPrimary w-full h-3/4 bottom-0' />
            <div className='flex flex-row justify-center w-2/3 mb-4 z-10'>
              <img className='h-full max-h-[20rem] w-full group-hover:-translate-y-4 object-cover transition-transform' src={img} />
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='text-white font-menlo font-bold text-lg tracking-wide'>{name}</span>
          <span className='text-white text-md tracking-wide'>{role}</span>
          <span className='text-white text-sm mt-4'>{text}</span>
        </div>
      </div>
    </>
  )
}

import { isMobile, isDesktop } from 'react-device-detect'

export const ProcessPage = () => {


  return (
    <>
      test
    </>
  )
}

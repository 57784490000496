import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const CaseStudyHybridSection = ({ title, img, text, direction }) => {
  return (
    <>
        <div className='w-full xl:w-4/5 2xl:w-3/4 my-6 px-8'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 '>
                <div className='lg:col-span-2'>
                    {title &&
                        <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'> {title} </h2>
                    }
                    <p className='text-white text-lg font-avenir font-light whitespace-pre-line'> {text} </p>
                </div>
                <div className={'flex w-full justify-center ' + ((direction === 'left') ? ' md:order-first' : '')}>
                    <img className='h-full w-auto self-center object-contain' src={img} />
                </div>
            </div>
        </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import api from '../js/api'
import { ProjectCardNew } from '../Components/ProjectCardNew'
import { useProjects } from '../js/projectsList'
import { isMobile, isDesktop } from 'react-device-detect'

export const DemosPage = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.demo()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])

  let pageData
  if (data) {
    pageData = data.data.attributes
    console.log(pageData)
  }

  return (
    <>
      <iframe src={pageData?.iframeUrl} className='h-screen w-screen'></iframe>
    </>
  )
}

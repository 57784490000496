import React from 'react'
import { InvidarCarousel } from '../Components/InvidarCarousel'

export const Carousel = (props) => {
  return (
    <>
      <InvidarCarousel />
    </>
  )
}

import React from 'react'
import { isBrowser } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
// import { LanguageSelector } from './LanguageSelector'

export default function Sidebar (props) {
  const [t] = useTranslation('global')
  const routes = [
    { title: t('app.home'), path: '/', id: 'homeSidebar' },
    { title: t('app.about'), path: '/about', id: 'aboutSidebar' },
    // { title: t('app.process'), path: '/process', id: 'processSidebar' },
    // { title: t('app.casestudies'), path: '/casestudies', id: 'casestudiesSidebar' },
    // { title: t('app.demospage'), path: '/demo', id: 'demosSidebar' },
    // { title: t('app.services'), path: '/services', id: 'servicesSidebar' },
    { title: t('app.contact'), path: '/contact', id: 'contactSidebar' }
  ]
  const socialMedia = [
    { title: 'linkedinLogo', type: 'link', href: 'https://www.linkedin.com/company/invidar/', element: <svg className={(props.showMenu ? 'icon' : 'icon') + ' mb-1'} xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'><path d='M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z' /></svg> },
    { title: 'twitterLogo', type: 'link', href: 'https://twitter.com/INVIDAR_UK', element: <svg className={(props.showMenu ? 'icon' : 'icon')} xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'><path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' /></svg> },
    { title: 'postLogo', id: 'mail', element: <svg className={(props.showMenu ? 'icon' : 'icon')} onClick={() => props.setShowContact(!props.showContact)} xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'><path d='M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z' /></svg> }
  ]
  const location = useLocation()
  return (
    <>
      <div className={(props.showMenu ? 'opacity-1 bg-invidarLight lg:bg-transparent' : 'opacity-0 pointer-events-none') + ' fixed h-full lg:mt-20 w-full z-30 transition-all duration-1000'} onClick={() => props.setShowMenu(false)} />
      <div className='fixed left-4 sm:left-8 z-50 mix-blend-difference' style={{ top: '24.5px' }}>
        <div id='sidebarButton' onClick={() => props.setShowMenu(!props.showMenu)} className='menuStacks cursor-pointer h-10 w-10 mt-5 text-invidarLight'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 8' fill='white' className={(props.showMenu ? 'menu-close' : '') + ' stack-1 transition-all duration-500'}>
            <path d='m 33.5 0 q 6.5 0 6.5 7 q 0 1 -1 1 h -37.5 q -1.5 0 -1.5 -1.5 v -5 q 0 -1.5 1.5 -1.5 z' />
          </svg>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 8' fill='white' className={(props.showMenu ? 'menu-close' : '') + ' stack-2 my-1 transition-all duration-500'}>
            <path d='m 38.5 0 q 1.5 0 1.5 1.5 l 0 5 q 0 1.5 -1.5 1.5 h -37 q -1.5 0 -1.5 -1.5 v -5 q 0 -1.5 1.5 -1.5 z' />
          </svg>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 8' fill='white' className={(props.showMenu ? 'menu-close' : '') + ' stack-3 transition-all duration-500'}>
            <path d='m 38.5 0 q 1.5 0 1.5 1.5 l 0 5 q 0 1.5 -1.5 1.5 h -37 q -1.5 0 -1.5 -1.5 v -5 q 0 -1.5 1.5 -1.5 z' />
          </svg>
        </div>
      </div>
      <CSSTransition in={props.showMenu} unmountOnExit classNames='showMenu' timeout={700}>
        <div id='sidebarContent' className='bg-transparent lg:bg-invidarLight h-full fixed w-screen lg:w-96 left-0 pb-12 flex flex-col justify-start top-0 z-40 overflow-y-scroll lg:overflow-y-hidden'>
          <div className='flex justify-end lg:bg-invidarLight'>
            <div className='pt-12 pb-4 flex flex-row justify-around pl-24 lg:pl-0 w-full lg:w-9/12'>
              {/* <LanguageSelector /> */}
            </div>
          </div>
          <div className='flex flex-col text-invidarDark pr-6 pl-12 pt-12'>
            {routes.map((route, index) => {
              return <Link id={route.id} onClick={() => (location.pathname !== route.path) && props.setShowMenu(false)} className='py-4 mt-4 lg:mt-2 flex hover:text-invidarPrimary transition-all lg:font-normal duration-200 cursor-pointer text-[1.94rem] font-menlo' to={route.path} key={index}>{route.title}</Link>
            })}
            {(!isBrowser || window.innerWidth < 1024) &&
              <div className='flex flex-row lg:hidden pt-12'>
                {socialMedia.map((logo, index) => {
                  if (logo.type === 'link') {
                    return <a id={logo?.id} href={logo.href} aria-label={logo.title} target='_blank' className=' mr-4 text-invidarDark hover:border-invidarPrimary transition-all duration-300' style={{ paddingRight: '10px', paddingTop: '9px' }} key={index} rel='noreferrer'>{logo.element}</a>
                  }
                  return <button id={logo?.id} href={logo.href} aria-label={logo.title} target='_blank' className='mr-4 mt-1 text-invidarDark hover:border-invidarPrimary transition-all duration-300' style={{ paddingRight: '10px', paddingTop: '9px' }} key={index} rel='noreferrer'>{logo.element}</button>
                })}
              </div>}
          </div>
        </div>
      </CSSTransition>
      {isBrowser && <div className={((props.showMenu) ? '' : '') + ' mix-blend-difference top-3/8 transition-all duration-700 hidden lg:flex flex-col uppercase px-10 fixed z-50 right-0'}>
        {socialMedia.map((logo, index) => {
          if (logo.type === 'link') {
            return <a id={logo?.id} href={logo.href} aria-label={logo.title} target='_blank' className='my-3 cursor-pointer' key={index} rel='noreferrer'>{logo.element}</a>
          }
          return <button id={logo?.id} href={logo.href} aria-label={logo.title} className='my-3 cursor-pointer' key={index}>{logo.element}</button>
        })}
      </div>}
    </>
  )
}

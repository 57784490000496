import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { useTranslation } from 'react-i18next'
import { ContactDetails } from '../Components/ContactDetails'
import { ContactForm } from '../Components/ContactForm'
import { DemoForm } from '../Components/DemoForm'
import api from '../js/api'

export const Contact = ({ setShowContact }) => {
  const [data, setData] = useState(null)
  const [t] = useTranslation('global')
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.contactUs()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className='content min-h-screen bg-invidarMedium'>
        <div className='flex flex-wrap flex-col md:flex-row w-full h-full pt-28 justify-center'>
          <div className='w-full xl:w-4/5 2xl:w-3/4 mt-12 px-8'>
            <h1 className='text-white font-menlo font-bold text-[2.5rem]'>{t('app.contactus')}</h1>
            {/* <p className='text-white font-light text-[1.125rem] mt-6'>Cras pharetra leo a velit luctus, non ornare ligula congue. In pretium gravida imperdiet. Vestibulum non elit sit amet nisi volutpat porttitor vitae non eros. Nam mollis eleifend ex, nec commodo leo faucibus vitae.</p> */}
            <div className='my-12'>
              <Tabs>
                <TabList className='w-full grid grid-cols-2 gap-4'>
                  <Tab className='bg-invidarDark text-white text-center uppercase text-xl font-light tracking-wider py-4 mb-4 transition-[letter-spacing] hover:tracking-widest cursor-pointer' selectedClassName='!mb-0 hover:tracking-wider cursor-default'>Get in touch</Tab>
                  <Tab className='bg-invidarDark text-white text-center uppercase text-xl font-light tracking-wider py-4 mb-4 transition-[letter-spacing] hover:tracking-widest cursor-pointer' selectedClassName='!mb-0 hover:tracking-wider cursor-default'>Book a demo</Tab>
                </TabList>
                <TabPanel className='bg-invidarDark w-full text-white'>
                  <div className='p-8'>
                    <ContactForm />
                  </div>
                </TabPanel>
                <TabPanel className='bg-invidarDark w-full text-white'>
                  <div className='p-8'>
                    <DemoForm />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const CaseStudyBanner = ({ img }) => {
  return (
    <>
        <div className='w-full my-6'>
            <img className='w-screen max-h-[40rem] object-cover' src={img} />
        </div>
    </>
  )
}

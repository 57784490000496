import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getImgFullURL } from '../js/helpers/imgHelper'

export const AboutClients = ({ clients, workWithTitle }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 6000,
    arrows: false,
    variableWidth: true,
    mobileFirst: true,
    cssEase: 'linear',
    dots: false,
    pauseOnHover: false
  }
  return (
    <div className='bg-invidarDark py-10 w-full flex flex-col'>
      {/* <div className='flex flex-row justify-end'>
        <div className='flex flex-col mx-10 lg:mx-0 w-full lg:w-11/12 space-y-8 lg:pr-40'>
          <h3 className='text-invidarPrimary w-full uppercase font-thin mb-6 lg:ml-20'>{workWithTitle}</h3>
        </div>
      </div> */}
      <Slider {...settings} className='flex continuous my-4'>
        {
          clients?.data.map((client, index) => {
            return (
              <div key={index} className='!w-72'>
                <img alt={client.attributes.name} className='h-12 mx-auto' src={getImgFullURL(client.attributes.logo.data.attributes.url)} />
              </div>
            )
          })
        }
      </Slider>
    </div>
  )
}

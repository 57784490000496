import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const CaseStudyTextSection = ({ title, text }) => {
  return (
    <>
        <div className='w-full xl:w-4/5 2xl:w-3/4 my-6 px-8'>
            <div className=''>
                {title &&
                    <h2 className='text-white font-menlo font-bold text-[2rem] pb-2 mb-4 border-b-[1px] border-invidarPrimary'> {title} </h2>
                }
                <p className='text-white text-lg font-avenir font-light whitespace-pre-line'> {text} </p>
            </div>
        </div>
    </>
  )
}
